import React from 'react';
import Layout from '../components/layout';
import MilkLink from '../components/milk-link';
import SEO from '../components/seo';
import A80 from '../animations/a80';
import A160 from '../animations/a160';
import A400 from '../animations/a400';
import Drop from '../animations/drop';

export default ({ pageContext: { content, pages, lang, texts, langs } }) => {
  const data = content.data;
  const items = pages.filter((page) => page[lang].template === 'news')[0][lang]
    .items;
  let liquid3;
  let liquid4;
  let liquid5;
  if (items.length > 2) {
    liquid3 = <A80 className='news-liquid-3' />;
  }
  if (items.length > 4) {
    liquid4 = <A400 className='news-liquid-4' />;
  }
  if (items.length > 8) {
    liquid5 = <A160 className='news-liquid-5' />;
  }

  let posts = null;

  if (data.columns_3) {
    posts = (
      <div className='posts posts--3'>
        {items.map((item, index) => {
          return (
            <div
              data-sal='slide-up'
              data-sal-duration='1000'
              key={index}
            >
              <div className='img-wrap'>
                <img src={item.image} alt={item.title} title={item.title} />
                <MilkLink to={`/${lang}${item.url}`}></MilkLink>
              </div>
              <div className='date'>{item.date}</div>
              <div
                className='h3'
                dangerouslySetInnerHTML={{ __html: item.title }}
              />
              <MilkLink
                className='more border-link cnv-link'
                to={`/${lang}${item.url}`}
              >
                {data.read_more}
                <Drop />
              </MilkLink>
            </div>
          );
        })}
      </div>
    );
  } else {
    posts = (
      <div className='posts'>
        {items.map((item, index) => {
          return (
            <div
              data-sal={index % 2 === 0 ? 'slide-right' : 'slide-left'}
              data-sal-duration='1000'
              key={index}
            >
              <div className='img-wrap'>
                <img src={item.image} alt={item.title} title={item.title} />
                <MilkLink to={`/${lang}${item.url}`}></MilkLink>
              </div>
              <div className='date'>{item.date}</div>
              <div
                className='h3'
                dangerouslySetInnerHTML={{ __html: item.title }}
              />
              <MilkLink
                className='more border-link cnv-link'
                to={`/${lang}${item.url}`}
              >
                {data.read_more}
                <Drop />
              </MilkLink>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <Layout pages={pages} lang={lang} texts={texts} langs={langs}>
      <SEO
        description={data.seo_description}
        title={data.seo_title}
        image={data.seo_image}
      />
      <div className='layer-top layer-top-news'>
        <A160 className='news-liquid-1' />
        <A160 className='news-liquid-2' />
        {liquid3}
        {liquid4}
        {liquid5}
        <div className='relative'>
          <div className='small-wrap'>
            <h1
              class="h1--wide"
              data-sal='slide-right'
              data-sal-duration='1000'
              dangerouslySetInnerHTML={{ __html: data.intro_title }}
            />
            {posts}
            {/*
                        <div className="text-center">
                            <span className="btn btn-more">{data.show_more}<Svg name="plus" /></span>
                        </div>
                        */}
          </div>
        </div>
      </div>
    </Layout>
  );
};
